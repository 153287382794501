import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import { vMaska } from "maska/vue"
import MaterialDashboard from "./material-dashboard";
import money from 'v-money3'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Aura from '@primevue/themes/lara';

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(
  PrimeVue, {
    theme: {
        preset: Aura,
        options: {
            prefix: 'p',
            cssLayer: false,
            darkModeSelector: '.my-app-dark',
          },
    },
 }
);
appInstance.use(ToastService);
appInstance.use(MaterialDashboard);
appInstance.directive(vMaska);
appInstance.use(money);
appInstance.mount("#app");
