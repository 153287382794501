<template>
  <dialog :open="isOpen" class="default-dialog">
    <div class="dialog-content">
      <header class="dialog-header">
        <div>
          <h2 class="dialog-title">{{ title }}</h2>
        </div>
        <button @click="closeModal" class="close-icon-button">✖</button>
      </header>

      <ul class="awarded-quotes-list" v-if="awardedQuotes.length > 0 && this.isAddingQuote == false">
        <li v-for="(quote, index) in awardedQuotes" :key="index">
          <div class="awarded-quotes-item">
            <div>
              <span class="quote-number">
                <strong>{{ quote.position }}º</strong>
                <strong>- Prêmio:</strong>
                {{ quote.premio }}
              </span>
            </div>

            <button class="remove-quote-btn">
              <i class="material-icons" @click="removeQuote(index)">delete</i>
            </button>
          </div>
        </li>
      </ul>

      <div v-else-if="awardedQuotes.length == 0 && this.isAddingQuote == false" class="no-quotes">
        <i class="material-icons">sentiment_dissatisfied</i>
        <span>
          Nenhuma premiação foi adicionada...
        </span>
      </div>

      <main class="dialog-body">
        <div v-if="!isAddingQuote">
          <button
            class="btn mb-0 submit-button"
            @click.prevent="showQuoteForm"
          >
            <span>Adicionar prêmio</span>
            <i class="material-icons ticket-icon">add</i>
          </button>
        </div>
        <div v-else class="quote-form">
          <div class="input-group input-group-outline m-0 d-flex flex-column">
            <label class="input-label">
              <span class="text-xs text-uppercase text-body font-weight-bolder"
                >Prêmio</span
              >
              <input
                v-model="newQuote.premio"
                type="text"
                class="form-control default-bordered-input"
              />
            </label>
          </div>
          
          <div class="button-field">
            <button @click="saveQuote" class="btn submit-button" :disabled="!isFieldsFilled">Salvar</button>
            <button @click="cancelQuoteCreation" class="btn cancel-button">Cancelar</button>
          </div>
        </div>
      </main>
    </div>
  </dialog>
</template>

<script>
import { vMaska } from "maska/vue";

export default {
  directives: {
    maska: vMaska,
  },
  props: {
    isOpen: Boolean,
  },
  data() {
    return {
      title: 'Premiações da campanha',
      awardedQuotes: [],
      isAddingQuote: false,
      newQuote: {
        premio: ''
      }
    };
  },
  watch: {
    awardedQuotes: {
      handler(newValue) {
        this.$emit('update:awardedQuotes', newValue);
      },
      deep: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
      this.resetForm();
    },
    showQuoteForm() {
      this.isAddingQuote = true;
    },
    saveQuote() {
      if (this.newQuote.premio) {
        this.awardedQuotes.push(
          {
            ...this.newQuote,
            position: this.awardedQuotes.length + 1
          }
        );
        this.resetForm();
      } else {
        alert("Por favor, preencha todos os campos.");
      }
    },
    removeQuote(index) {
      this.awardedQuotes.splice(index, 1);
    },
    cancelQuoteCreation() {
      this.resetForm();
    },
    resetForm() {
      this.newQuote.premio = '';
      this.isAddingQuote = false;
    }
  },
  computed: {
    isFieldsFilled() {
      return this.newQuote.premio.trim() !== '';
    }
  }
};
</script>

<style scoped lang="scss">
.default-dialog {
  border: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  background: none;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
}

dialog {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

dialog[open] {
  opacity: 1;
  pointer-events: inherit;
}

dialog::backdrop {
  backdrop-filter: blur(2px);
  animation: fadein 0.2s linear;
}

.dialog-content {
  width: 600px;
  max-width: 100%;
  background: white;
  border-radius: 8px;
  padding: 20px;
  z-index: 2;
  transform: scale(0.96);
  transition: transform 0.2s;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

dialog[open] .dialog-content {
  transform: scale(1);
}

.dialog-title {
  font-family: sans-serif;
  font-size: 26px;
  color: #111111;
  margin: 0;
}

.modal-overlay {
  position: fixed;
  display: flex;
  top: 0;
  align-items: center;
  justify-self: center;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(144, 149, 160, 0.5);
  color: #9095a0;
  z-index: 9999999;
  backdrop-filter: blur(4px);
  width: 100vw;
  height: 100vh;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dialog-body {
  margin: 20px 0 0 0;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.button-field {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.cancel-button {
  background-color: #e0e0e0;
  color: #0f172a;
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 6px;
  line-height: 0;
  margin-bottom: 0;

  &:hover {
    background-color: #d6d6d6;
    color: #0f172a;
  }
}

.close-icon-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.submit-button {
  background-color: #661cb0;
  color: white;
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 6px;
  line-height: 0;
  position: relative;
  transition: background-color 0.2s ease;
  cursor: pointer;
  margin-bottom: 0;

  &:hover {
    background-color: #7b23d3;
    color: white;
  }
}

.quote-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.quote-form input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.awarded-quotes-list {
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
}

.awarded-quotes-item {
  border: 1px solid #d2d6da !important;
  padding: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 4px;
  color: #36454F;
}

.quote-number {
  display: flex;
  gap: 5px;
  align-items: center;
}

.no-quotes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 40px 0 40px 0;
  color: #3c3d42;
}

.remove-quote-btn {
  border: none;
  background-color: transparent;
  padding: 0; margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

