<template>
  <div class="image-upload-container">
    <label class="image-label">
      <span class="image-label-title">Imagens</span>
      <span class="recommended-size">
        <strong>Tamanho recomendado:</strong> 1365x758 pixels
      </span>
    </label>

    <div class="upload-box">
      <input 
        type="file" 
        accept="image/*" 
        multiple 
        ref="fileInput" 
        class="file-input" 
        @change="handleFileChange" 
        style="display: none"
      />
      <div class="image-grid">
        <div v-for="(image, index) in images" :key="index" class="image-preview-container">
          <img :src="image.src" class="image-preview" />
          <button class="delete-button" @click="removeImage(index)">
            &#10006;
          </button>
        </div>

        <div v-if="images.length < 5" class="upload-placeholder" @click="triggerFileInput">
          <i class="material-icons">folder</i>
        </div>
      </div>
    </div>

    <p v-if="images.length >= 5" class="image-limit-warning">Você atingiu o limite de 5 imagens.</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: JSON.parse(localStorage.getItem('uploadedImages')) || [],
    };
  },
  methods: {
    triggerFileInput() {
      if (this.images.length < 5) {
        this.$refs.fileInput.click();
      }
    },
    handleFileChange(event) {
      const files = Array.from(event.target.files);
      const maxFiles = 5 - this.images.length;

      files.slice(0, maxFiles).forEach((file) => {
        this.previewImage(file);
      });

      this.emitImages();

      this.$refs.fileInput.value = "";
    },
    previewImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.images.push({ src: e.target.result });
        this.saveImagesToLocalStorage();
        this.emitImages();
      };
      reader.readAsDataURL(file);
    },
    removeImage(index) {
      this.images.splice(index, 1);
      this.saveImagesToLocalStorage();
      this.emitImages();
    },
    saveImagesToLocalStorage() {
      localStorage.setItem('uploadedImages', JSON.stringify(this.images));
    },
    emitImages() {
      const base64Files = this.images.map(image => image.src);
      this.$emit('update:images', base64Files);
    },
  },
};
</script>

<style scoped>
.image-upload-container {
  margin-bottom: 16px;
}

.image-label {
  display: block;
  margin: 0 0px 10px 0;
}

.image-label-title {
  font-weight: bold;
}

.recommended-size {
  background-color: #dcfce7;
  color: #166534;
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 10px;
  font-size: 14px;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.image-preview-container {
  position: relative;
  width: 240px;
  height: 140px;
}

.image-preview {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 2px solid #b3c2f2;
  border-radius: 4px;
}

.upload-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 140px;
  border: 2px dashed #b3c2f2;
  cursor: pointer;
  border-radius: 4px;
}

.delete-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.image-limit-warning {
  color: red;
  margin-top: 10px;
  font-weight: bold;
  font-size: 12px;
}
</style>
