<template>
  <div class="p-4 container-fluid form-new-raffle card card-body">
    <h4 class="new-campaign-title">
      <i class="material-icons ticket-icon">confirmation_number</i>
      {{ pageTitle }}
    </h4>

    <form class="campaing-form">
      <DefaultInput
        v-model="newRaffleForm.name"
        label="Título da campanha"
        placeholder="Insira o título de sua campanha"
      />

      <div class="input-group input-group-outline m-0 d-flex flex-column">
        <label class="input-label">
          <span class="text-xs text-uppercase text-body font-weight-bolder"
            >Modelo</span
          >
          <select class="form-control default-bordered-input">
            <option value="MANUAL">Cliente escolhe as cotas manualmente</option>
            <option value="AUTO">Sistema escolhe as cotas aleatóriamente</option>
          </select>
        </label>
      </div>

      <div>
        <span class="text-xs text-uppercase text-body font-weight-bolder">
          Descrição / Regulamento
        </span>
        <Editor :key="editorKey" :model-value="newRaffleForm.description" v-model="newRaffleForm.description" editorStyle="height: 320px" class="default-bordered-input" />
      </div>

      <div>
        <images-input
          v-model="newRaffleForm.images"
          @update:images="updateImages"
        />
      </div>
      
      <div class="d-flex flex-direction-row gap-3 three-fields">
        <div class="input-group input-group-outline m-0 d-flex flex-column">
          <label class="input-label">
            <span class="text-xs text-uppercase text-body font-weight-bolder"
              >Quantidade de cotas</span
            >
            <select v-model.number="newRaffleForm.qnt_quotas" class="form-control default-bordered-input">
              <option
                v-for="quota in quotas"
                :key="quota.value"
                :value="quota.value"
              >
                {{ quota.text }}
              </option>
            </select>
          </label>
        </div>

        <div class="input-group input-group-outline m-0 d-flex flex-column">
          <label class="input-label">
            <span class="text-xs text-uppercase text-body font-weight-bolder"
              >Quantidade minima de cotas por compra</span
            >
            <input
              v-model.number="newRaffleForm.minimum_purchase"
              type="text"
              class="form-control default-bordered-input maska"
              data-maska-number-locale="br"
              data-maska-number
              placeholder="Insira a quantidade mínima"
            />
          </label>
        </div>

        <div class="input-group input-group-outline m-0 d-flex flex-column">
          <label class="input-label">
            <span class="text-xs text-uppercase text-body font-weight-bolder"
              >Quantidade máxima de cotas por compra</span
            >
            <input
              v-model.number="newRaffleForm.maximum_purchase"
              type="text"
              class="form-control default-bordered-input maska"
              data-maska-number-locale="br"
              data-maska-number
              placeholder="Insira a quantidade máxima"
            />
          </label>
        </div>
      </div>

      <div class="d-flex flex-direction-row gap-3 three-fields">
        <div class="input-group input-group-outline m-0 d-flex flex-column">
          <label class="input-label">
            <span class="text-xs text-uppercase text-body font-weight-bolder"
              >Valor das cotas</span
            >
            <input
              v-model.lazy="newRaffleForm.quota_price"
              v-money3="config"
              type="text"
              class="form-control default-bordered-input"
            />
          </label>
        </div>

        <div class="input-group input-group-outline m-0 d-flex flex-column">
          <label class="input-label">
            <span class="text-xs text-uppercase text-body font-weight-bolder"
              >Local de sorteio</span
            >
            <select v-model="newRaffleForm.drawer" class="form-control default-bordered-input" id="exampleFormControlSelect1">
              <option
                v-for="drawer in raffleDrawer"
                :key="drawer.value"
                :value="drawer.value"
              >
                {{ drawer.text }}
              </option>
            </select>
          </label>
        </div>

        <div class="input-group input-group-outline m-0 d-flex flex-column">
          <label class="input-label">
            <span class="text-xs text-uppercase text-body font-weight-bolder"
              >Filtro inicial das cotas*</span
            >
            <select v-model="newRaffleForm.show_type" class="form-control default-bordered-input" id="exampleFormControlSelect1">
              <option
                v-for="type in showType"
                :key="type.value"
                :value="type.value"
              >
                {{ type.text }}
              </option>
            </select>
          </label>
        </div>
      </div>

      <div class="input-group input-group-outline m-0 d-flex flex-column">
        <label class="input-label">
          <span class="text-xs text-uppercase text-body font-weight-bolder"
            >Número de celular</span
          >
          <input
            v-model="newRaffleForm.phone"
            type="text"
            class="form-control default-bordered-input"
            v-maska="'(##) #####-####'"
            placeholder="(xx) xxxxx-xxxx"
          />
        </label>
      </div>

      <div class="d-flex flex-direction-row gap-3">
        <div class="input-group input-group-outline m-0 d-flex flex-column">
          <label class="input-label">
            <span class="text-xs text-uppercase text-body font-weight-bolder"
              >Tempo para pagamento</span
            >
            <select v-model="newRaffleForm.payment_time" class="form-control default-bordered-input" id="exampleFormControlSelect1">
              <option
                v-for="duration in durations"
                :key="duration.value"
                :value="duration.value"
              >
                {{ duration.text }}
              </option>
            </select>
          </label>
        </div>

        <div class="input-group input-group-outline m-0 d-flex flex-column" v-if="newRaffleForm.hasDate">
          <label class="input-label">
            <span class="text-xs text-uppercase text-body font-weight-bolder"
              >Informar data</span
            >
            <input
              v-model="newRaffleForm.draw_date"
              type="date"
              class="form-control default-bordered-input"
            />
          </label>
        </div>
      </div>

      <div class="d-flex flex-direction-row gap-3 three-fields">
        <div class="input-group input-group-outline m-0 d-flex flex-column check-field">
          <label class="input-label">
            <span class="text-xs text-uppercase text-body font-weight-bolder">
              Data de sorteio
            </span>

            <div class="form-check form-switch mt-2 switch-field">
              <input class="form-check-input" type="checkbox" v-model="newRaffleForm.hasDate">
              <label class="form-check-label">Informar data</label>
            </div>
          </label>
        </div>

        <div class="input-group input-group-outline m-0 d-flex flex-column check-field">
          <label class="input-label">
            <span class="text-xs text-uppercase text-body font-weight-bolder">
              Top 3 ranking
            </span>

            <div class="form-check form-switch mt-2 switch-field">
              <input class="form-check-input" type="checkbox" v-model="newRaffleForm.show_ranking">
              <label class="form-check-label">Exibir ranking</label>
            </div>
          </label>
        </div>
      </div>

      <div class="d-flex flex-direction-row gap-3 three-fields">
        <button
          class="btn mb-0 option-button"
          @click.prevent="this.isAwardedQuotesOpen = true"
        >
          <i class="material-icons">local_activity</i>
          Cota premiada
        </button>

        <button
          class="btn mb-0 option-button"
          @click.prevent="this.prizesDialog = true"
        >
          <i class="material-icons">emoji_events</i>
          Prêmio
        </button>

        <button
          class="btn mb-0 option-button"
          @click.prevent
        >
          <i class="material-icons">paid</i>
          Promoção
        </button>
      </div>

      <div class="total-value">
        <span class="total-value-title">
          Taxas de publicação
        </span>

        <div>
          <div class="total-value-values">
            <span>Taxa de publicação</span>
            <span class="negative"> - {{ publicationFee }} </span>
          </div>

          <div class="total-value-values">
            <span>Arrecadação estimada</span>
            <span class="positive"> + {{ estimatedProfit }} </span>
          </div>
        </div>
      </div>

      <div class="mt-2 full-width d-flex justify-content-between">
        <button
          class="btn mb-0 cancel-button"
          @click.prevent="this.$router.push('/')"
        >
          Cancelar
        </button>

        <button
          class="btn mb-0 submit-button"
          @click.prevent="submitRaffle"
        >
          Criar campanha
          <i class="material-icons ticket-icon">arrow_forward</i>
        </button>
      </div>
    </form>

    <AwardedQuoteDialog
      :isOpen="isAwardedQuotesOpen"
      @close="this.isAwardedQuotesOpen = false"
      v-model:awardedQuotes="this.newRaffleForm.awarded_quotas"
    />
    
    <PrizesDialog
      :isOpen="prizesDialog"
      @close="this.prizesDialog = false"
      v-model:awardedQuotes="this.newRaffleForm.awards"
    />
  </div>

  <Loading v-if="loading" />
</template>

<script>
import { MaskInput } from "maska";
import { vMaska } from "maska/vue";
import Editor from 'primevue/editor';
import ImagesInput from '@/views/components/ImagesInput.vue';
import AwardedQuoteDialog from '@/views/Raffles/RafflesCreation/components/AwardedQuoteDialog.vue';
import PrizesDialog from '@/views/Raffles/RafflesCreation/components/PrizesDialog.vue';
import RafflesHttp from "../../../http/RafflesHttp";
import { RouteName } from "../../../store/routeName";
import Loading from '@/components/Loading.vue'
import DefaultInput from "@/components/DefaultInput.vue";

export default {
  name: "new-raffle",
  components: {
    Editor,
    ImagesInput,
    AwardedQuoteDialog,
    PrizesDialog,
    Loading,
    DefaultInput
  },
  directives: {
    maska: vMaska,
  },
  data() {
    return {
      loading: false,
      innerWidth: window.innerWidth,
      isAwardedQuotesOpen: false,
      prizesDialog: false,
      newRaffleForm: {
        id_user: 0,
        name: "",
        qnt_quotas: 100,
        quota_price: "0",
        minimum_purchase: null,
        maximum_purchase: null,
        drawer: "LOTERIA",
        phone: "",
        type: "MANUAL",
        description: "",
        draw_date: "",
        hasDate: false,
        payment_time: "1h",
        show_type: "ALL_QUOTAS",
        show_ranking: false,
        images: [],
        awards: [
          {
            position: 1,
            award: "Casa",
          },
          {
            position: 2,
            award: "Carro",
          },
        ],
        awarded_quotas: [
          {
            quota: null,
            award: "",
          },
        ],
      },
      quotas: [
        { value: 25, text: "25 cotas" },
        { value: 50, text: "50 cotas" },
        { value: 100, text: "100 cotas" },
        { value: 200, text: "200 cotas" },
        { value: 300, text: "300 cotas" },
        { value: 400, text: "400 cotas" },
        { value: 500, text: "500 cotas" },
        { value: 600, text: "600 cotas" },
        { value: 700, text: "700 cotas" },
        { value: 800, text: "800 cotas" },
        { value: 900, text: "900 cotas" },
        { value: 1000, text: "1.000 cotas" },
        { value: 2000, text: "2.000 cotas" },
        { value: 3000, text: "3.000 cotas" },
        { value: 4000, text: "4.000 cotas" },
        { value: 5000, text: "5.000 cotas" },
        { value: 6000, text: "6.000 cotas" },
        { value: 10000, text: "10.000 cotas" },
        { value: 20000, text: "20.000 cotas" },
        { value: 30000, text: "30.000 cotas" },
        { value: 50000, text: "50.000 cotas" },
        { value: 100000, text: "100.000 cotas" },
        { value: 500000, text: "500.000 cotas" },
        { value: 1000000, text: "1.000.000 cotas" },
        { value: 1500000, text: "1.500.000 cotas" },
        { value: 10000000, text: "10.000.000 cotas" },
      ],
      durations: [
        { value: "10m", text: "10 minutos" },
        { value: "30m", text: "30 minutos" },
        { value: "1h", text: "1 hora" },
        { value: "1d", text: "1 dia" },
        { value: "3d", text: "3 dias" },
      ],
      raffleDrawer: [
        { value: "LOTERIA", text: "Loteria Federal" },
        { value: "SORTEADOR", text: "Sorteador.com.br" },
        { value: "INSTAGRAM", text: "Live no Instagram" },
        { value: "YOUTUBE", text: "Live no Youtube" },
        { value: "TIKTOK", text: "Live no TikTok" },
        { value: "OTHER", text: "Outros" },
      ],
      showType: [
        { value: "ALL_QUOTAS", text: "Mostrar todas as cotas" },
        { value: "AVAILABLE_QUOTAS", text: "Mostrar somente cotas disponíveis" },
      ],
      editorKey: 0,
    };
  },
  computed: {
    config() {
      return {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        disableNegative: true,
        masked: false,
      };
    },
    pageTitle() {
      return this.$route.name == RouteName.NewRaffle ? 'Nova campanha' : 'Editar campanha';
    },
    publicationFee() {
    const totalAmount = this.newRaffleForm.qnt_quotas * this.parseQuotaPrice(this.newRaffleForm.quota_price);
    if (totalAmount === 0) return "R$ 0,00";

    if (totalAmount >= 0 && totalAmount <= 100) return "R$ 7,00";
    if (totalAmount > 100 && totalAmount <= 200) return "R$ 17,00";
    if (totalAmount > 200 && totalAmount <= 400) return "R$ 27,00";
    if (totalAmount > 400 && totalAmount <= 700) return "R$ 37,00";
    if (totalAmount > 700 && totalAmount <= 1000) return "R$ 47,00";
    if (totalAmount > 1000 && totalAmount <= 2000) return "R$ 67,00";
    if (totalAmount > 2000 && totalAmount <= 4000) return "R$ 77,00";
    if (totalAmount > 4000 && totalAmount <= 7000) return "R$ 127,00";
    if (totalAmount > 7000 && totalAmount <= 10000) return "R$ 197,00";
    if (totalAmount > 10000 && totalAmount <= 20000) return "R$ 247,00";
    if (totalAmount > 20000 && totalAmount <= 30000) return "R$ 497,00";
    if (totalAmount > 30000 && totalAmount <= 50000) return "R$ 997,00";
    if (totalAmount > 50000 && totalAmount <= 70000) return "R$ 1.497,00";
    if (totalAmount > 70000 && totalAmount <= 100000) return "R$ 1.997,00";
    if (totalAmount > 100000 && totalAmount <= 150000) return "R$ 2.997,00";
    if (totalAmount > 150000) return "R$ 3.997,00";

    return "R$ 0,00"; // Caso padrão, embora não deva ocorrer se a lógica estiver correta
  },

  estimatedProfit() {
    return this.newRaffleForm.qnt_quotas && this.newRaffleForm.quota_price
      ? (this.newRaffleForm.qnt_quotas * this.parseQuotaPrice(this.newRaffleForm.quota_price)).toLocaleString(('pt-BR'), {
        style: 'currency',
        currency: 'BRL'
      })
      : '0.00';
  }
  },
  methods: {
    updateImages(array) {
      this.newRaffleForm.images = array;
    },
    async createRaffle() {
      try {
        const user = JSON.parse(localStorage.getItem('user'));

        const newRaffleUpdatedForm = {
          ...this.newRaffleForm,
          id_user: user.id,
          quota_price: this.parseQuotaPrice(this.newRaffleForm.quota_price).toString(),
          draw_date: this.getDateIfChecked()
        }

        await RafflesHttp.createRaffle(newRaffleUpdatedForm);
        this.showToast();
        this.$router.push('/');
      } catch (error) {
        this.$toast.add({ severity: 'error', summary: 'Ops...', detail: 'Ocorreu um erro. Tente novamente!', life: 3000 });
      }
    },
    async updateRaffle() {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        
        const updatedRaffleForm = {
          ...this.newRaffleForm,
          id_user: user.id,
          quota_price: this.parseQuotaPrice(this.newRaffleForm.quota_price).toString(),
          draw_date: this.getDateIfChecked()
        };

        console.log(updatedRaffleForm)

        await RafflesHttp.updateRaffle(this.newRaffleForm.id, updatedRaffleForm);
        this.showToast('success', 'Sucesso!', 'Sua campanha foi atualizada.');
        this.$router.push('/');
      } catch (error) {
        this.$toast.add({ severity: 'error', summary: 'Ops...', detail: 'Ocorreu um erro. Tente novamente!', life: 3000 });
      }
    },
    submitRaffle() {
      if (this.$route.name == RouteName.EditRaffle) {
        this.updateRaffle();
      } else {
        this.createRaffle();
      }
    },
    parseQuotaPrice(price) {
      let numericValue = price.replace(/[^\d,]+/g, '');

      numericValue = numericValue.replace(',', '.');

      return parseFloat(numericValue);
    },
    getDateIfChecked() {
      if(this.newRaffleForm.hasDate) {
        return this.newRaffleForm.draw_date;
      } else return null;
    },
    showToast() {
      this.$toast.add({ severity: 'success', summary: 'Sucesso!', detail: 'Sua campanha foi criada e aguarda pagamento.', life: 3000 });
    },
    async getRaffle() {
      this.loading = true;
      const raffle = await RafflesHttp.getRaffle(this.$route.params.slug)
      console.log(raffle)
      this.newRaffleForm = raffle.data;
      this.editorKey += 1;
      this.loading = false;
    }
  },
  mounted() {
    new MaskInput(".maska");
    if(this.$route.name == RouteName.EditRaffle) {
      this.getRaffle();
    }
  },
  watch: {
    newRaffleForm: {
      handler(newValue) {
        console.log(newValue)
      },
      deep: true,
    },
  }
};
</script>

<style scoped lang="scss">
.campaing-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.input-label {
  margin: 0;
  padding: 0;
}
.new-campaign-title {
  font-size: 28px;
  margin-bottom: 24px;
  display: flex;
  color: #0f172a;
  align-items: center;
  gap: 8px;
}
.ticket-icon {
  font-size: 24px;
}
.submit-button {
  background-color: #22c55e;
  color: white;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  gap: 6px;
  line-height: 0;

    .btn-icon {
      font-size: 1.4em;
    }

    &:hover {
      transform: scale(1.04);
      background-color: #169444;
      color: white;
    }
}

.cancel-button {
  background-color: #e0e0e0;
  color: #0f172a;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  gap: 6px;
  line-height: 0;

    .btn-icon {
      font-size: 1.4em;
    }

    &:hover {
      transform: scale(1.04);
      background-color: #d6d6d6;
      color: #0f172a;
    }
}

.option-button {
  background-color: #ffffff;
  border: 1px solid #d2d6da !important;
  color: #0f172a;
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 6px;
  line-height: 0;

    .btn-icon {
      font-size: 1.4em;
    }

    &:hover {
      background-color: #f8fafc;
      color: #0f172a;
    }
}

.form-switch .form-check-input {
  border-color: #169444;
  
  &:checked {
    background-color: #169444;
  }
  &:checked::after {
    border-color: #cdd3d9;
    box-shadow: none;
  }
  &:active::after {
    transition: box-shadow 0.1s ease-in-out;
    box-shadow: none;
  }
  &:checked:active::after {
    transition: box-shadow 0.1s ease-in-out;
    box-shadow: none;
  }
}

.switch-field {
  display: flex;
  align-items: center;
  gap: 14px;

  label {
    margin: 0;
  }
}

.check-field {
  border-radius: 6px;
  border: 1px solid #d2d6da;
  padding: 14px;
}

.total-value {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  padding: 12px 20px;
  min-height: 100px;
  border: 1px solid #d2d6da;
  color: #0f172a;
  border-radius: 14px;

  &-title {
    font-size: 22px;
    margin-bottom: 8px;
    font-weight: bolder;
  }

  &-values {
    font-size: 16px;
    width: 100%;
    display: flex;
    color: #7b809a;
    justify-content: space-between;
  }
}

.negative {
  color: #dc2626;
}

.positive {
  color: #1ba54e;
}

@media(max-width:1000px) {
  .three-fields {
    flex-direction: column;
  }
}
</style>
