<template>
  <div class="input-group input-group-outline m-0 d-flex flex-column">
    <label class="input-label">
      <span class="text-xs text-uppercase text-body font-weight-bolder">
        {{ label }}
      </span>
      <select
        :value="modelValue"
        class="form-control default-bordered-input"
        :disabled="disabled"
        @change="handleSelect($event.target.value)"
      >
        <option v-if="placeholder" value="" disabled selected>{{ placeholder }}</option>
        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option[itemValue]"
        >
          {{ option[itemText] }}
        </option>
      </select>
    </label>
  </div>
</template>

<script>
export default {
  name: 'DefaultSelect',
  props: {
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "label",
    },
    itemValue: {
      type: String,
      default: "value",
    },
  },
  methods: {
    handleSelect(value) {
      this.$emit('update:modelValue', value);
    },
  },
};
</script>
