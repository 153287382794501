import api from '@/configs/GlobalRequestHandler.js';

export default {
  createRaffle:(raffleBody) => {
    return api.postRequestWithBody('/campaigns', raffleBody)
  },
  updateRaffle:(raffleId, raffleBody) => {
    return api.putRequestWithBody(`/campaigns/${raffleId}`, raffleBody)
  },
  getRaffle:(raffleSlug) => {
    return api.getRequest(`/campaigns/${raffleSlug}`)
  },
}