<!--
=========================================================
* Vue Material Dashboard 2 - v3.1.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-material-dashboard-2
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<template>
  <div id="app" class="d-flex flex-column min-vh-100">
    <sidenav
      :custom_class="color"
      :class="[isRTL ? 'fixed-end' : 'fixed-start']"
      v-if="showSidenav"
    />
    <main
      class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden"
      :class="innerWidth > 1200 ? 'px-5' : 'px-3'"
    >
      <!-- nav -->
      <navbar
        :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
        :color="isAbsolute ? 'text-white opacity-8' : ''"
        :minNav="navbarMinimize"
        v-if="showNavbar"
      />
      <router-view />
      <Toast/>
      <app-footer v-show="showFooter"/>
    </main>
  </div>
</template>
<script>
import Sidenav from "./examples/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations, mapState } from "vuex";
import axios from 'axios'
import config from '@/store/config'
import Toast from 'primevue/toast';

const api = axios.create({
  baseURL: config.apiUrl
})
export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
    AppFooter,
    Toast
  },
  data() {
    return {
      innerWidth: window.innerWidth
    };
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
    async verifyAuth() {
      if(this.$route.path == "/sign-up"){
        return;
      }
      const token = localStorage.getItem("token");

      if (token) {
        try {
          const response = await api.get("/validate", await this.$store.state.config);

          if(response.data.status == 401){
            this.$router.push("/sign-in");
          }
        } catch (error) {
            this.$router.push("/sign-in");
            console.error(error);
        }
      }else{
        this.$router.push("/sign-in");
      }
      
    },
  },
  computed: {
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
    ]),
  },
  watch: {
    "$route"() {
      this.verifyAuth();
    }
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";

    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }
  },
};
</script>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex-grow: 1;
}
</style>