<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="d-flex align-items-center justify-content-center">
        <div class="mb-4 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted"
            :class="$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
          >
            ©
            {{ new Date().getFullYear() }}, feito com
            <i class="fa fa-heart"></i> por
            <a
              class="font-weight-bold cursor-pointer"
              target="_blank"
              >Rifa 365</a
            >.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
};
</script>
