<template>
  <div class="input-group input-group-outline m-0 d-flex flex-column">
    <label class="input-label">
      <span class="text-xs text-uppercase text-body font-weight-bolder">
        {{ label }}
      </span>
      <input
        :value="modelValue"
        :type="type"
        class="form-control default-bordered-input"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="handleInput($event.target.value)"
      />
    </label>
  </div>
</template>

<script>
export default {
  name: 'DefaultInput',
  props: {
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('update:modelValue', value);
    },
  },
};
</script>
