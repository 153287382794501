import axios from 'axios';

const environment = process.env.VUE_APP_ROOT_API;

const http = axios.create({
  baseURL: environment
});

http.interceptors.request.use(
  (config) => {
      const token = localStorage.getItem('token');
      
      if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
      }

      return config;
  },
  (error) => {
      return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    return Promise.reject(error.response.data || error.response || error);
  }
)

export default http;