<template>
  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-3"
    :class="`${
      isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'
    } ${computedSidebarType}`"
  >
    <div class="sidenav-header">
      <button
        @click="toggleSidebar"
        class="minimize-button"
        v-if="shouldShowIcon"
      >
        <i class="material-icons">
          menu
        </i>
      </button>

      <a class="m-0 navbar-brand" href="/">
        <img
          :src="
            computedSidebarType === 'bg-white' ||
            (computedSidebarType === 'bg-transparent' && !isDarkMode)
              ? logo
              : logo
          "
          class="navbar-brand-img navbar-logo"
          alt="main_logo"
        />
      </a>
    </div>
    <sidenav-list />
  </aside>
</template>

<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo.png";
import logoDark from "@/assets/img/logo-ct-dark.png";
import { mapMutations, mapState } from "vuex";
import { useWindowSize } from "@/store/useWindowSize";

export default {
  name: "index",
  components: {
    SidenavList,
  },
  setup() {
    const { windowWidth } = useWindowSize();

    return {
      windowWidth,
    };
  },
  data() {
    return {
      logo,
      logoDark,
    };
  },
  computed: {
    ...mapState(["isRTL", "sidebarType", "isDarkMode"]),
    computedSidebarType() {
      return this.windowWidth >= 1200 ? "bg-transparent" : "bg-white";
    },
    shouldShowIcon() {
      return this.windowWidth <= 1200;
    },
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    toggleSidebar() {
      this.navbarMinimize();
    },
  },
};
</script>


<style scoped lang="scss">
.minimize-button {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  
  &:hover {
    color: #7b23d3;
    transform: scale(1.2);
  }
  
  &:active {
    transform: scale(0.95);
  }
}

.sidenav-header {
  height: auto;
}

#sidenav-main {
  padding: 12px;
}
</style>