<template>
  <div class="container-fluid p-0">
    <div class="card card-body">
      <div class="row">
        <div
          class="mx-auto mt-3 col-lg-12 my-sm-auto ms-sm-auto me-sm-0"
        >
          <div class="nav-wrapper position-relative end-0">
            <ul
              class="p-1 nav nav-pills nav-fill"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  :class="{'px-0': true, 'py-1': true, 'mb-0': true,  'nav-link': true, 'active': page == 'account'}"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  aria-selected="true"
                  @click.prevent="page = 'account'"
                >
        
                  <span class="ms-1">Conta</span>
                </a>
              </li>

              <li class="nav-item">
                <a
                  :class="{'px-0': true, 'py-1': true, 'mb-0': true,  'nav-link': true, 'active': page == 'payment_methods'}"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  aria-selected="true"
                  @click.prevent="page = 'payment_methods'"
                >
        
                  <span class="ms-1">Métodos de Pagamento</span>
                </a>
              </li>

              <li class="nav-item">
                <a
                  :class="{'px-0': true, 'py-1': true, 'mb-0': true,  'nav-link': true, 'active': page == 'social'}"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  aria-selected="true"
                  @click.prevent="page = 'social'"
                >
                 
        
                  <span class="ms-1">Redes Sociais</span>
                </a>
              </li>

              <li class="nav-item">
                <a
                  :class="{'px-0': true, 'py-1': true, 'mb-0': true,  'nav-link': true, 'active': page == 'customization'}"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  aria-selected="true"
                  @click.prevent="page = 'customization'"
                >
                 
        
                  <span class="ms-1">Personalização</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mt-3 row">
          <div class="col-12 position-relative" v-if="page == 'account'">
            <div class="card card-plain h-100">
              <div class="p-3 pb-0 card-header">
                <h6 class="mb-0">Conta</h6>
              </div>
              <div class="p-3 card-body">
                <div class="account-form mb-3">
                  <div>
                    <ProfilePictureInput :value="user.profile" @updateUserImage="updateUserImage" />
                  </div>

                  <div class="account-form-input">
                    <DefaultInput
                      v-model="user.full_name"
                      label="Nome"
                      placeholder="Insira seu nome"
                    />

                    <DefaultInput
                      v-model="user.email"
                      label="E-mail"
                      placeholder="Insira seu e-mail"
                    />

                    <DefaultInput
                      v-model="user.phone"
                      label="WhatsApp"
                      placeholder="Insira seu nome"
                      @keyup="maskPhone"
                    />
                  </div>
                </div>

                <div class="button-field">
                  <button
                    class="btn mb-0 mt-3 submit-button"
                    @click.prevent="updateUserData"
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-12 col-xl-12 position-relative" v-if="page == 'payment_methods'">
            <div class="card card-plain h-100">
              <div class="p-3 pb-0 card-header">
                <h6 class="mb-0">Métodos de Pagamento</h6>
              </div>
              <div class="p-3 card-body">

                <div class="card-payments">
                  <div class="card-payments-row">
                    <div class="payment-icon">
                      <img class="payment-logo" src="@/assets/img/payments/paggue.svg">
                    </div>

                    <div class="payment-titles">
                      <div>
                        <span>Paggue</span>
                        <span class="badge-green">Baixa automática</span>
                      </div>

                      <section style="display:flex; gap: 12px;">
                        <button v-if="showDefaultPaymentTypeButton('PAGGUE')" class="btn-settings" :disabled="isDefaultProvider('PAGGUE')" @click="setDefaultPaymentProvider('PAGGUE')">
                          <i v-if="isDefaultProvider('PAGGUE')" class="material-icons" style="font-size: 16px;">check_circle</i>
                          {{ isDefaultProvider('PAGGUE') ? 'Método padrão' : 'Definir como padrão' }}
                        </button>
                        <button class="btn-settings" @click="modalPaggue = true">
                          <i class="material-icons" style="font-size: 16px;">settings</i>
                          Configurar
                        </button>
                      </section>
                    </div>
                  </div>

                  <p>
                    Receba pagamentos via Pix diretamente na sua conta do Paggue.
                  </p>
                </div>

                <div class="card-payments">
                  <div class="card-payments-row">
                    <div class="payment-icon">
                      <img class="payment-logo" src="@/assets/img/payments/pay2m.png" alt="">
                    </div>

                    <div class="payment-titles">
                      <div>
                        <span>Pay2m</span>
                        <span class="badge-green">Baixa automática</span>
                      </div>

                      <section style="display:flex; gap: 12px;">
                        <button v-if="showDefaultPaymentTypeButton('PAY2M')" class="btn-settings" :disabled="isDefaultProvider('PAY2M')" @click="setDefaultPaymentProvider('PAY2M')">
                          <i v-if="isDefaultProvider('PAY2M')" class="material-icons" style="font-size: 16px;">check_circle</i>
                          {{ isDefaultProvider('PAY2M') ? 'Método padrão' : 'Definir como padrão' }}
                        </button>
                        <button class="btn-settings" @click="modalPixPay2m = true">
                          <i class="material-icons" style="font-size: 16px;">settings</i>
                          Configurar
                        </button>
                      </section>
                    </div>
                  </div>

                  <p>
                    Receba pagamentos via Pix diretamente na sua conta do Pay2M.
                  </p>
                </div>

                <div class="card-payments">
                  <div class="card-payments-row">
                    <div class="payment-icon">
                      <img class="payment-logo" src="@/assets/img/payments/pix.svg">
                    </div>

                    <div class="payment-titles">
                      <div>
                        <span>Pix</span>
                        <span class="badge-orange">Baixa manual</span>
                      </div>

                      <section style="display:flex; gap: 12px;">
                        <button v-if="showDefaultPaymentTypeButton('MANUAL')" class="btn-settings" :disabled="isDefaultProvider('MANUAL')" @click="setDefaultPaymentProvider('MANUAL')">
                          <i v-if="isDefaultProvider('MANUAL')" class="material-icons" style="font-size: 16px;">check_circle</i>
                          {{ isDefaultProvider('MANUAL') ? 'Método padrão' : 'Definir como padrão' }}
                        </button>
                        <button class="btn-settings" @click="modalPixManual = true">
                          <i class="material-icons" style="font-size: 16px;">settings</i>
                          Configurar
                        </button>
                      </section>
                    </div>
                  </div>

                  <p>
                    Receba pagamentos via Pix diretamente na sua conta.
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-12 position-relative" v-if="page == 'social'">
            <div class="p-2 h-100">
              <div class="pb-0 mb-3">
                <h6 class="mb-0">Redes Sociais</h6>
              </div>
              <div class="social-medias">
                <DefaultInput
                  v-model="user.whatsapp"
                  label="Grupo no WhatsApp"
                  placeholder="Digite o link aqui..."
                  class="mb-2"
                />

                <DefaultInput
                  v-model="user.telegram"
                  label="Grupo no Telegram"
                  placeholder="Digite o link aqui..."
                  class="mb-2"
                />

                <DefaultInput
                  v-model="user.instagram"
                  label="Perfil no Instagram"
                  placeholder="Digite o link aqui..."
                  class="mb-2"
                />

                <DefaultInput
                  v-model="user.tiktok"
                  label="Perfil no Tiktok"
                  placeholder="Digite o link aqui..."
                  class="mb-2"
                />

                <DefaultInput
                  v-model="user.youtube"
                  label="Canal no Youtube"
                  placeholder="Digite o link aqui..."
                  class="mb-2"
                />
              </div>

              <div class="button-field">
                <button
                  class="btn mb-0 mt-3 submit-button"
                  @click.prevent="updateUserData"
                >
                  Salvar
                </button>
              </div>
            </div>
          </div>

          <div class="m-0 p-0" v-if="page == 'customization'">
            <div class="card card-plain h-100">
              <div class="p-3 pb-0 card-header">
                <h6 class="mb-0">Personalização</h6>
              </div>
              <div class="p-3 card-body">
                <div class="account-form mb-3">
                  <!-- <div>
                    <ProfilePictureInput :value="user.logo" placeholder="Alterar logo" @updateUserImage="updateLogoImage" />
                  </div> -->

                  <div class="account-form-input">
                    <!-- <div class="logo-alert">
                      <span>
                        O Rifa365 permite a personalização da experiência do usuário, oferecendo a opção de substituir a imagem do logo, que será refletida tanto na visão do administrador quanto na do cliente. Para garantir a melhor apresentação, recomenda-se utilizar o tamanho padrão para o logo: 300 x 100 px.
                      </span>
                    </div> -->
                    
                    <DefaultInput
                      v-model="user.domain"
                      label="Domínio"
                      placeholder="Insira o domínio"
                      class="mb-2"
                    />
                  </div>
                </div>

                <div class="button-field">
                  <button
                    class="btn mb-0 mt-3 submit-button"
                    @click.prevent="updateUserData"
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </div>
            <hr class="vertical dark" />
          </div>
        </div>
      </div>
    </div>


    <PaggueFormDialog
      :isOpen="modalPaggue"
      @close="this.modalPaggue = false"
      v-model:paggueForm="this.paggue"
      :receivedPaggue="this.paggue"
      @updatePayment="updateCredentials('paggue')"
    />

    <Pay2mFormDialog
      :isOpen="modalPixPay2m"
      @close="this.modalPixPay2m = false"
      v-model:pay2mForm="this.pay2m"
      :receivedPay2m="this.pay2m"
      @updatePayment="updateCredentials('pay2m')"
    />

    <PixFormDialog
      :isOpen="modalPixManual"
      @close="this.modalPixManual = false"
      v-model:pixForm="this.manual"
      :receivedPixForm="this.manual"
      @updatePayment="updateCredentials('manual')"
    />

  <Loading v-if="loading" />
</div>
  
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import axios from 'axios'
import config from '@/store/config'
import Loading from '@/components/Loading.vue'
import DefaultInput from "../../components/DefaultInput.vue";
import PaggueFormDialog from "./components/PaggueFormDialog.vue";
import Pay2mFormDialog from "./components/Pay2mFormDialog.vue";
import ProfilePictureInput from "@/components/ProfilePictureInput.vue"
import PixFormDialog from "./components/PixFormDialog.vue"

const api = axios.create({
  baseURL: config.apiUrl
})

function mphone(v) {
  var r = v.replace(/\D/g, "");
  r = r.replace(/^0/, "");
  if (r.length > 10) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (r.length > 5) {
    r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else {
    r = r.replace(/^(\d*)/, "($1");
  }
  return r;
}

export default {
  name: "profile-overview",
  data() {
    return {
      defaultProfileImage: require('@/assets/img/default-user-icon.jpg'),
      page: "account",
      showMenu: false,
      loading: true,
      modalPixManual: false,
      modalPixPay2m: false,
      modalPixPaggue: false,
      modalPaggue: false,
      paymentMethods: [],
      user: {},
      manual: {
        pix_type: "",
        pix: "",
        name: ""
      },
      pay2m:{
        client_id: "",
        client_secret: ""
      },
      paggue: {
        client_key: "",
        client_secret: ""
      }
    };
  },
  components: {
    Loading,
    DefaultInput,
    PaggueFormDialog,
    ProfilePictureInput,
    Pay2mFormDialog,
    PixFormDialog
  },

  async mounted(){
    await this.init()
    setNavPills();
  },

  watch: {
    "$store.state.isDarkMode": function () {
      if(this.$store.state.isDarkMode){
        document.querySelectorAll('.input-default').forEach((el) => {
          el.classList.add('dark-mode-input')
        })
      }else{
        document.querySelectorAll('.input-default').forEach((el) => {
          el.classList.remove('dark-mode-input')
        })
      }
    },
  },

  methods:{
    async init(){
      this.loading = true
      let payment_methods = this.getPaymentMethods()
      let user_data = this.getUserData()

      Promise.all([payment_methods, user_data]).then(() => {
        this.loading = false
      })
    },
    async getUserData(){
      try {
        const response = await api.get("/users", await this.$store.state.config);
        this.user = response.data;
        this.user.phone = mphone(this.user.phone);
      } catch (error) {
        console.error(error);
      }
    },
    async getPaymentMethods(){
      try {
        const response = await api.get("/payment_provider", await this.$store.state.config);
        this.paymentMethods = response.data;

        for (const e of this.paymentMethods) {
          this[e.name.toLowerCase()] = JSON.parse(e.data)

        }
      } catch (error) {
        console.error(error);
      }
    },
    async updateCredentials(name){
      try {
        this.loading = true
        let data = {
          data: JSON.stringify(this[name])
        }
        await api.put(`/payment_provider/${name}`, data, await this.$store.state.config);
        this.getPaymentMethods()
        this.modalPixManual = false
        this.modalPixPay2m = false
        this.loading = false
      } catch (error) {
        console.error(error);
      }
    },
    async updateUserData(){
      try {
        this.loading = true
        let response = await api.put(`/users`, this.user, await this.$store.state.config);
        if(response.data.error){
          this.$toast.error(response.data.error)
          return
        }
        this.getUserData()
        this.loading = false
      } catch (error) {
        alert(error.response.data.error)
        this.loading = false
      }
    },
    async maskPhone() {
      this.user.phone = mphone(this.user.phone);
    },
    async setDefaultPaymentProvider(name) {
      const pay2m = this.paymentMethods.find((item) => item.name == name);
      
      if(pay2m != null) {
        this.user.default_provider = pay2m.id;
        await this.updateUserData();
      }
    },
    updateUserImage(newImage) {
      this.user.profile = newImage;
    },
    updateLogoImage(newImage) {
      this.user.logo = newImage;
    },
  },
  computed: {
    isDefaultProvider() {
      return (paymentMethodName) => {
        const paymentMethod = this.paymentMethods.find(
          (method) => method.name === paymentMethodName
        );

        return paymentMethod 
          ? this.user.default_provider === paymentMethod.id
          : false;
      };
    },
    showDefaultPaymentTypeButton() {
      return (paymentMethodName) => {
        const paymentMethod = this.paymentMethods.find(
          (method) => method.name === paymentMethodName
        );

        if (!paymentMethod || !paymentMethod.data) {
          return false;
        }

        try {
          const parsedData = JSON.parse(paymentMethod.data);
          return typeof parsedData === 'object' && !Array.isArray(parsedData) && Object.keys(parsedData).length > 0;
        } catch (error) {
          console.error(error);
          return false;
        }
      }
    }
  }
};
</script>

<style scoped>
.input-default{
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
}

.dark-mode-input{
    background-color: #1a2035 !important;
    border: 1px solid #10131f !important;
    color: #fff;
  }

.active{
  background-color: #f8f9fa;
  color: #e91e63 !important;
  border-radius: 10px;
  font-weight: bold;
}

.card-payments{
  width: 100%;
  border: 1px solid #d2d6da;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.card-payments .payment-logo{
  width: 25px;
}

.card-payments span{
  font-size: 20px;
  font-weight: bold;
  font-size: 20px;
  font-weight: 500;
  margin-right: 15px;
}
.card-payments p{
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: -4px;
}
.btn-settings{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  float: right;
  border: 1px solid #d2d6da;
  background-color: transparent;
  border-radius: 5px;
  font-size: 11pt;
  padding: 5px 10px;
}
.size-4 {
  width: 1rem;
  height: 1rem;
}

.badge-green{
    display: inline-flex;
    align-items: center;
    border-radius: .375rem;
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 244 / var(--tw-bg-opacity));
    padding: .1rem .3rem;
    text-transform: capitalize;
    font-size: .75rem !important;
    line-height: 1rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(21 128 61 / var(--tw-text-opacity));
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    --tw-ring-inset: inset;
    --tw-ring-color: rgb(22 163 74 / .2);
    border: 1pt solid #16a34a;
  }

  .badge-orange{
    display: inline-flex;
    align-items: center;
    border-radius: .375rem;
    --tw-bg-opacity: 1;
    background-color: rgb(253 236 234 / var(--tw-bg-opacity));
    padding: .1rem .3rem;
    text-transform: capitalize;
    font-size: .75rem !important;
    line-height: 1rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(231 166 41 / var(--tw-text-opacity));
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    --tw-ring-inset: inset;
    --tw-ring-color: rgba(226, 144, 50, 0.2);
    border: 1pt solid #e9a72e;
  }

  /* modal */
  

  .modal-default{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background-color: #000000ad;
    z-index: 9999;
  }

  .account-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 25px;
  }

  .account-form-input {
    width: 100%;
  }

  .account-img-field {
    width: 180px;
    height: 180px;
    border-radius: 1000px;
  }

  .social-medias {
    display: grid;
    grid-template-columns: 49% 49%;
    justify-content: space-between;
  }

  .submit-button {
    background-color: #22c55e;
    color: white;
    display: flex;
    height: 40px;
    min-width: 160px;
    align-items: center;
    justify-content: center;
    gap: 6px;
    line-height: 0;

    .btn-icon {
      font-size: 1.4em;
    }

    &:hover {
      transform: scale(1.04);
      background-color: #169444;
      color: white;
    }
  }

  .payment-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: #f1f5f9;
    margin-right: 8px;
  }

  .payment-titles {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .card-payments-row {
    display: flex;
    justify-content: row;
    align-items: center;
  }

  .button-field {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .logo-alert {
    background-color: #2352d3;
    border-radius: 14px;
    padding: 14px;
    margin-bottom: 10px;

    span {
      color: white;
      font-size: 15px;
    }
  }

@media(max-width:1000px) {
  .account-form {
    flex-direction: column;
  }
}
</style>