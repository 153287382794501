<template>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg p-4 d-flex justify-content-between align-items-center "
            >
              <h6 class="text-white text-capitalize m-0">Campanhas</h6>

              <button class="btn btn-icon btn-3 campaign-btn m-0" type="button" @click="newRaffle">
                <span class="btn-inner--icon"><i class="material-icons btn-icon">confirmation_number</i></span>
                <span class="btn-inner--text btn-text">NOVA CAMPANHA</span>
              </button>
            </div>
          </div>

          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0" v-if="raffles.length > 0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Campanha
                    </th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Status
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                      Cotas Vendidas
                    </th>
                    <th class="text-secondary opacity-7"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(r, index) in raffles" :key="r.id">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div>
                          <img
                            :src="r.images[0]"
                            class="avatar avatar-sm me-3 border-radius-lg"
                            alt="user1"
                          />
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">{{ r.name }}</h6>
                          <p class="text-xs text-secondary mb-0">
                            Criado em: {{ new Date(r.created_at).toLocaleDateString() }}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span class="badge badge-sm bg-gradient-warning" v-if="r.status == 'WAITING'">AGUARDANDO PAGAMENTO</span>
                      <span class="badge badge-sm bg-gradient-success" v-if="r.status == 'ACTIVE'">ATIVA</span>
                      <span class="badge badge-sm bg-gradient-secondary" v-if="r.status == 'FINISHED'">FINALIZADA</span>
                    </td>

                    <td class="align-middle text-center">
                      <div class="d-flex align-items-center justify-content-center">
                        <span class="text-xs font-weight-bold">{{(((r.qnt_quotas - r.remaining_quotas) * 100) / r.qnt_quotas)}}%</span>
                        <div>
                          <div class="progress mx-2">
                            <div
                              class="progress-bar bg-gradient-success"
                              role="progressbar"
                              :aria-valuenow="(((r.qnt_quotas - r.remaining_quotas) * 100) / r.qnt_quotas)"
                              aria-valuemin="0"
                              :aria-valuemax="100"
                              :style="`width: ${(((r.qnt_quotas - r.remaining_quotas) * 100) / r.qnt_quotas)}%`"
                            ></div>
                          </div>
                        </div>
                        <span class="text-xs font-weight-bold">{{ (r.qnt_quotas - r.remaining_quotas).toLocaleString() }} / {{ r.qnt_quotas.toLocaleString() }}</span>
                      </div>
                    </td>
                    <td class="align-middle">
                      <button class="more-btn" @click="toggle(index, $event)">
                        <i class="material-icons">more_vert</i>
                      </button>
                      <PrimeMenu
                        ref="menu"
                        :id="`overlay_menu_${index}`"
                        :model="
                          [
                            ...items,
                            { label: 'Pagar',
                              command: () => {
                                this.$router.push(`/raffles/pay/${r.slug}`);
                              }
                            },
                            { label: 'Editar campanha',
                              command: () => {
                                this.$router.push(`/raffles/${r.slug}/edit`)
                              }
                            },
                            { label: 'Informar ganhador(es)',
                              command: () => {
                                this.$router.push(`/raffles/set-winners/${r.slug}`);
                              }
                            },
                          ]
                        "
                        :popup="true"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="no-campaign" v-else>
              <i class="material-icons">summarize</i>
              <span>Você ainda não criou nenhuma campanha.</span>
              <span>Para criar, basta clicar em <strong>criar campanha</strong> e preencher os dados solicitados.</span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading v-if="loading" />
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/store/config'
import Loading from '@/components/Loading.vue'
import PrimeMenu from 'primevue/menu'

const api = axios.create({
  baseURL: config.apiUrl
})

export default {
  name: "raffles",
  components: {
    Loading,
    PrimeMenu,
  },
  data() {
    return {
      raffles: [],
      loading: true,
      items: []
    };
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() { 
      this.loading = true;
      await this.getRaffles();
      this.loading = false;
    },
    async getRaffles() {
      try {
        const response = await api.get("/campaigns", await this.$store.state.config);
        this.raffles = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    newRaffle() {
      this.$router.push({ name: 'Nova campanha' });
    },
    toggle(index, event) {
      this.$refs.menu[index].toggle(event);
    }
  }
};
</script>

<style scoped lang="scss">
  .campaign-btn {
    background-color: #f0f2f5;
    color: #e41b80;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    line-height: 0;

    .btn-icon {
      font-size: 1.6em;
    }

    &:hover {
      transform: scale(1.04);
      color: #e41b80;
    }
  }

  .more-btn {
    background-color: transparent;
    border: none;
    padding: 0; margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .no-campaign {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
    padding: 50px 0;
  }
</style>
