<template>
    <div class="fill">
        <div class="img">
            <img src="@/assets/img/logo.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: "Loading",
}
</script>

<style>
.fill {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff98;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

@keyframes bounce {
    0% {
        transform: scale(0.8);
    }
    40% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.8);
    }
}

.img img {
    display: block;
    width: 300px;
    margin: 0 auto;
    animation: bounce 1.5s infinite;
}
</style>