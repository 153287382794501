<template>
  <nav
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl px-0"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
    :class="isAbsolute ? 'mt-4' : 'mt-0'"
  >
    <div class="px-0 py-1 d-flex justify-content-between w-100 navbar-field">
      <breadcrumbs :currentPage="currentRouteName" :color="color" class="navbar-breadcrumb" />
      
      <div
        class="mt-2 mt-sm-0 align-content-center navbar-actions"
        id="navbar"
      >
        <ul class="navbar-nav justify-content-end gap-4">
          <li class="nav-item d-flex align-items-center" v-if="isSidebarOpened">
            <button
              @click="toggleSidebar"
              class="logout-button"
            >
              <i class="material-icons">
                menu
              </i>
            </button>
          </li>
          
          <li class="nav-item d-flex align-items-center">
            <button
              @click="logout"
              class="logout-button"
            >
              <i class="material-icons">
                logout
              </i>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapState } from "vuex";
import { useWindowSize } from "@/store/useWindowSize";

export default {
  name: "navbar",
  setup() {
    const { windowWidth } = useWindowSize();

    return {
      windowWidth,
    };
  },
  data() {
    return {
      showMenu: false,
    };
  },
  props: ["minNav", "color"],
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),

    toggleSidebar() {
      this.navbarMinimize();
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.$router.push("/sign-in");
    }
  },
  components: {
    Breadcrumbs,
  },
  computed: {
    ...mapState(["isRTL", "isAbsolute", "isPinned"]),

    currentRouteName() {
      return this.$route.name;
    },
    isSidebarOpened() {
      return this.isPinned && this.windowWidth <= 1200;
    },
    userName() {
      return JSON.parse(localStorage.getItem('user')).full_name;
    },
  },
};
</script>

<style scoped lang="scss">
.logout-button {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  
  &:hover {
    color: #7b23d3;
    transform: scale(1.2);
  }
  
  &:active {
    transform: scale(0.95);
  }
}

.navbar-field {
  height: 50px;
}

.navbar-breadcrumb {
  display: flex;
  align-items: center;
}

.navbar-actions {
  background-color: white;
  padding-inline: 20px;
  border-radius: 1000px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
</style>