<template>
  <dialog :open="isOpen" class="default-dialog">
    <div class="dialog-content">
      <header class="dialog-header">
        <div>
          <h2 class="dialog-title">{{ title }}</h2>
        </div>
        <button @click="closeModal" class="close-icon-button">✖</button>
      </header>


      <main class="dialog-body">
        <div class="pix-alert">
          <span>
            Atenção! Os pagamentos via Pix manual não realizam a baixa automática. Você precisará confirmar manualmente o recebimento e marcar as reservas como pagas.
          </span>
        </div>

        <div class="quote-form">
          <DefaultSelect
            label="Tipo de chave"
            :options="[
              { label: 'Aleatória', value: 'Aleatória' },
              { label: 'Email', value: 'Email' },
              { label: 'Celular', value: 'Celular' },
              { label: 'CPF/CNPJ', value: 'CPF/CNPJ' },
            ]"
            v-model="pixForm.pix_type"
          />

          <DefaultInput
            v-model="pixForm.pix"
            label="Chave Pix"
            placeholder="Insira sua chave pix"
          />

          <DefaultInput
            v-model="pixForm.name"
            label="Nome do beneficiário"
            placeholder="Insira o nome completo do beneficiário"
          />

          <div class="button-field">
            <button @click="save" class="btn submit-button" :disabled="!isFieldsFilled">Salvar</button>
            <button @click="closeModal" class="btn cancel-button">Cancelar</button>
          </div>
        </div>
      </main>
    </div>
  </dialog>
</template>

<script>
import { vMaska } from "maska/vue";
import DefaultInput from "@/components/DefaultInput.vue";
import DefaultSelect from "@/components/DefaultSelect.vue";

export default {
  directives: {
    maska: vMaska,
  },
  components: {
    DefaultInput,
    DefaultSelect
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    receivedPixForm: {
      type: Object,
      default: () => ({
        pix_type: "",
        pix: "",
        name: ""
      })
    }
  },
  data() {
    return {
      title: 'Configuração Pix Manual',
      pixForm: { ...this.receivedPixForm }
    };
  },
  watch: {
    receivedPixForm: {
      handler(newPixForm) {
        if(newPixForm && Object.keys(newPixForm).length > 0) {
          this.pixForm = { ...newPixForm };
        }
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    save() {
      if (this.pixForm) {
        this.$emit('update:pixForm', this.pixForm);
        this.$emit('updatePayment');
        this.closeModal();
      } else {
        alert("Por favor, preencha todos os campos.");
      }
    },
    resetForm() {
      this.pixForm.pix = '';
      this.pixForm.name = '';
    }
  },
  computed: {
    isFieldsFilled() {
      return this.pixForm.pix.trim() !== '' && this.pixForm.name.trim() !== '';
    }
  }
};
</script>

<style scoped lang="scss">
.default-dialog {
  border: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  background: none;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
}

dialog {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

dialog[open] {
  opacity: 1;
  pointer-events: inherit;
}

dialog::backdrop {
  backdrop-filter: blur(2px);
  animation: fadein 0.2s linear;
}

.dialog-content {
  width: 600px;
  max-width: 100%;
  background: white;
  border-radius: 8px;
  padding: 20px;
  z-index: 2;
  transform: scale(0.96);
  transition: transform 0.2s;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

dialog[open] .dialog-content {
  transform: scale(1);
}

.dialog-title {
  font-family: sans-serif;
  font-size: 26px;
  color: #111111;
  margin: 0;
}

.modal-overlay {
  position: fixed;
  display: flex;
  top: 0;
  align-items: center;
  justify-self: center;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(144, 149, 160, 0.5);
  color: #9095a0;
  z-index: 9999999;
  backdrop-filter: blur(4px);
  width: 100vw;
  height: 100vh;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dialog-body {
  margin: 20px 0 0 0;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.button-field {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.cancel-button {
  background-color: #e0e0e0;
  color: #0f172a;
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 6px;
  line-height: 0;
  margin-bottom: 0;

  &:hover {
    background-color: #d6d6d6;
    color: #0f172a;
  }
}

.close-icon-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.submit-button {
  background-color: #661cb0;
  color: white;
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 6px;
  line-height: 0;
  position: relative;
  transition: background-color 0.2s ease;
  cursor: pointer;
  margin-bottom: 0;

  &:hover {
    background-color: #7b23d3;
    color: white;
  }
}

.quote-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.quote-form input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.awarded-quotes-list {
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
}

.awarded-quotes-item {
  border: 1px solid #d2d6da !important;
  padding: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 4px;
  color: #36454F;
}

.quote-number {
  display: flex;
  gap: 5px;
  align-items: center;
}

.no-quotes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 40px 0 40px 0;
  color: #3c3d42;
}

.remove-quote-btn {
  border: none;
  background-color: transparent;
  padding: 0; margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pix-alert {
  background-color: #2352d3;
  border-radius: 14px;
  padding: 14px;
  margin-bottom: 26px;

  span {
    color: white;
    font-size: 15px;
  }
}
</style>