<template>
  <div class="profile-image">
    <img class="photo" :src="src" />
    <label class="upload-button">
      <input
        class="file"
        type="file"
        accept=".jpg, .jpeg, .png"
        @change="onFileChange"
      />
      <i class="material-icons" aria-hidden="true">folder_open</i>
      <span>{{ placeholder }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "ProfilePictureInput",
  props: {
    value: { type: [String, Blob, File], default: "" },
    placeholder: { type: String, default: "Alterar foto de perfil" },
  },
  data: () => ({
    src: "",
  }),
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const base64String = e.target.result;
          this.src = base64String;
          this.$emit("updateUserImage", base64String);
        };

        reader.readAsDataURL(file);
      }
    },
  },
  created() {
    if (this.value instanceof Blob) {
      this.src = URL.createObjectURL(this.value);
    } else {
      this.src = this.value ?? "";
    }
  },
  watch: {
    value(newValue) {
      if (newValue instanceof Blob) {
        this.src = URL.createObjectURL(newValue);
      } else {
        this.src = this.value ?? "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.profile {
  display: flex;
  flex-direction: column;
  margin: 0 30%;
  align-items: center;
  justify-content: center;
}

.spin {
  animation: spin 1s infinite linear;
}

.profile-image {
  height: 200px;
  width: 200px;
  border-radius: 20%;
  overflow: hidden;
  position: relative;
  background-color: #070a33;
  border: 2px solid #b9b9b9;


  .upload-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #ffffff;
    line-height: 30px;
    font-size: 20px;
    cursor: pointer;
    border: none;
    transition:
      visibility 0.25s,
      opacity 0.25s linear;
    visibility: hidden;
    opacity: 0;
    margin: 0;
  }

  &:hover {
    .upload-button {
      background: rgba(0, 0, 0, 0.8);
      visibility: visible;
      opacity: 1;
    }
  }

  i {
    margin: 0;
  }

  span {
    font-size: 14px;
    margin: 0;
  }
}

.photo {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.file {
  display: none;
  margin: 0;
}

.profile-button {
  width: 100%;
  border-radius: 4px;
  margin: 4px;
}

.delete {
  height: 52px;
  color: #330707;
  margin-bottom: 50px;
}

.redefine {
  height: 52px;
  background-color: #ffffff;
  color: #ffffff;

  &.locked {
    cursor: not-allowed;
    background-color: #252525;
  }
}

@media only screen and (max-width: 600px) {
  .profile-image {
    height: 250px;
    width: 250px;
    border-radius: 20%;
    overflow: hidden;
    position: relative;
    background-color: #070a33;
    border: 1px solid grey;

    .upload-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5em;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: #ffffff;
      line-height: 30px;
      font-size: 20px;
      cursor: pointer;
      transition:
        visibility 0.25s,
        opacity 0.25s linear;
      visibility: hidden;
      opacity: 0;
    }

    &:hover {
      .upload-button {
        background: rgba(0, 0, 0, 0.6);
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .profile-button {
    width: 300px;
    border-radius: 4px;
    margin: 4px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
