<template>
  <div
    class="page-header align-items-start min-vh-100"
    style="background-color: transparent;"
  >
    <span class="mask opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-body">
              <img src="@/assets/img/logo.png" style="width: 40%;display: block;margin: 0 auto" alt="">

              <form role="form" class="text-start mt-3">
                <div>
                  <div class="input-group input-group-outline m-0 d-flex flex-column">
                    <label class="input-label">
                      <span class="text-xs text-uppercase text-body font-weight-bolder"
                        >E-mail</span
                      >
                      <input
                        v-model="email"
                        type="email"
                        class="form-control default-bordered-input"
                        placeholder="Insira seu email"
                      />
                    </label>
                  </div>
                </div>

                <div>
                  <div class="input-group input-group-outline m-0 d-flex flex-column">
                    <label class="input-label">
                      <span class="text-xs text-uppercase text-body font-weight-bolder"
                        >Senha</span
                      >
                      <input
                        v-model="password"
                        type="password"
                        class="form-control default-bordered-input"
                        placeholder="Insira sua senha"
                      />
                    </label>
                  </div>
                </div>
          
                <div class="text-center">
                  <button class="btn btn-icon btn-3 campaign-btn m-0" type="button" @click.prevent="auth">
                    <span class="btn-inner--text btn-text">Fazer login</span>
                  </button>
                </div>
                <p class="mt-4 text-sm text-center">
                  Ainda não tem conta?
                  <router-link
                    :to="{ name: 'SignUp' }"
                    class="text-success text-gradient font-weight-bold"
                    >Cadastre-se</router-link
                  >
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/store/config'

const api = axios.create({
  baseURL: config.apiUrl
})

import { mapMutations } from "vuex";

export default {
  name: "sign-in",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    this.verifyAuth()
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async handleInputValue(event) {
      const { name, value } = event.target;
      this[name] = value;
    },
    async verifyAuth() {
      const token = localStorage.getItem("token");

      if (token) {
        try {
          const response = await api.get("/validate", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          if(response.status == 200){
            this.$router.push({ name: 'Campanhas' })
          }
        } catch (error) {
            console.error(error);
        }
      }

    },
    async auth(){
      if(!this.email || !this.password){
        this.$toast.add({ severity: 'warn', summary: 'Preencha todos os campos.', detail: 'Verifique se os campos estão preenchidos corretamente e tente novamente.', life: 3000 });
        return;
      }
      try {
        const response = await api.post('/login', {
          email: this.email,
          password: this.password
        })
        if(response.status === 200 && response.data.token){
          localStorage.setItem('token', response.data.token)
          localStorage.setItem('user', JSON.stringify(response.data.user))
          this.$router.push({ name: 'Campanhas' })
          this.$store.commit('setConfig', {
            headers: {
              Authorization: `Bearer ${response.data.token}`
            }
          })
        }else{
          this.$toast.add({ severity: 'error', summary: 'Ocorreu um erro.', detail: 'Usuário ou senha inválidos.', life: 3000 });
        }
      } catch (error) {
        this.$toast.add({ severity: 'error', summary: 'Ocorreu um erro.', detail: 'Usuário ou senha inválidos.', life: 3000 });
      }
    }
  },
};
</script>

<style scoped lang="scss">
  .campaign-btn {
    color: #ffffff;
    background-color: #e41b80;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    line-height: 0;
    height: 40px;
    width: 100%;
    margin-top: 15px !important;

    .btn-icon {
      font-size: 1.6em;
    }

    &:hover {
      color: #ffffff;
    }
  }

  .more-btn {
    background-color: transparent;
    border: none;
    padding: 0; margin: 0;
  }

  .no-campaign {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
    padding: 50px 0;
  }
</style>
